<template>
  <h1 class="base-headline" v-bind="$attrs" v-on="$listeners">
    {{ headlineText }}
  </h1>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    text: {
      type: String,
      required: true,
    },
    maxLength: {
      type: [Number, String],
    },
  },
  computed: {
    headlineText() {
      try {
        const maxLength = parseInt(this.maxLength);
        if (Number.isInteger(maxLength)) {
          if (this.text.length > this.maxLength) {
            return this.text
              .slice(0, this.maxLength)
              .padEnd(maxLength + 3, ".");
          }
        }
        return this.text;
      } catch (err) {
        return this.text;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>