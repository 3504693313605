<template>
  <label class="base-label" v-bind="$attrs" v-on="$listeners">
    <slot />
  </label>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.base-label {
  color: #bd7eee;
  font-weight: 500;
  font-size: 22px;
}
</style>
