<template>
  <div>
    <div>Countries test</div>

    <div
      class="countriesSVGContainer"
      ref="countriesSVGContainer"
      :class="$style.countriesSVGContainer"
    >
      <CountriesSVG :class="$style.countriesSVG" ref="countriesSVG" />
    </div>
  </div>
</template>

<script>
import CountriesSVG from "./countries.svg?inline";
import panzoom from "panzoom";
export default {
  components: {
    CountriesSVG,
  },
  mounted() {
    this.isMapZoomedIn = false;
    console.log("el", this, this.$el);
    const spain = this.$el.querySelector("#spain");
    console.log("spain", spain, spain.childNodes);
    const useEl = spain.childNodes[1];
    useEl.setAttribute("fill", "#000");
    spain.addEventListener("click", (e) => {
      console.log("country clicked", e, e.currentTarget, e.relatedTarget);
      //   e.currentTarget.childNodes[1].setAttribute("fill", "yellow");
      this.zoomInCountry(e.currentTarget);
    });
    this.zoomInCountry(this.$el.querySelector("#south_africa"));
    // this.$refs.countriesSVG.addEventListener("drag", this.onDragMap);

    this.mapPanzoom = panzoom(this.$refs.countriesSVG.childNodes[1], {
      beforeWheel: () => true,
      beforeMouseDown: () => this.isMapZoomedIn,
    });
  },
  beforeDestroy() {
    this.mapPanzoom.dispose();
  },
  methods: {
    // getMousePos(e) {
    //   return {
    //     x: e.clientX,
    //     y: e.clientY,
    //   };
    // },
    // getMousePosDiff({ x, y }) {
    //   return {
    //     x: this.mousePos.x - x,
    //     y: this.mousePos.y - y,
    //   };
    // },
    // initMoveMap() {
    //   // this.mousePos = this.getMousePos();
    //   console.warn(
    //     "adding mousemove listener",
    //     this.$refs.countriesSVG.childNodes[1]
    //   );
    //   // window.addEventListener("mousemove", this.onMoveMap);
    //   // this.mapPanzoom = panzoom(this.$refs.countriesSVG.childNodes[1], {
    //   //   beforeWheel: () => false,
    //   // });
    // },
    // removeMoveMap() {
    //   console.warn("removing mousemove listener");
    //   // window.removeEventListener("mousemove", this.onMoveMap);
    // },
    // onMoveMap(e) {
    //   if (!this.mousePos) {
    //     this.mousePos = this.getMousePos(e);
    //     return;
    //   }
    //   console.log("on move", e);
    //   const currentMousePos = this.getMousePos(e);
    //   console.table({ currentMousePos });
    //   const posDiff = this.getMousePosDiff(currentMousePos);
    //   console.log("posdif", posDiff);

    //   const translateX = this.mapTranslate.translateX - posDiff.x;
    //   const translateY = this.mapTranslate.translateY - posDiff.y;
    //   this.updateMapTransformStyle(translateX, translateY);

    //   //   this.mousePos = this.currentMousePos;
    //   // TODO: Calculate the mouse position different
    //   // and update the transform trnaslate of the countries SVG accordingly
    // },
    getElCenterPosition(boundingRect) {
      const { left, top, width, height } = boundingRect;
      return {
        centerX: left + width / 2,
        centerY: top + height / 2,
      };
    },
    // updateMapTransformStyle(translateX, translateY) {
    //   console.log("updating map transform style", translateX, translateY);
    //   this.$refs.countriesSVG.style.transform = `scale(3.75) translate(${translateX}px, ${translateY}px)`;
    // },
    zoomInCountry(el) {
      const $container = this.$refs.countriesSVG;
      const countryBoundingRect = el.getBoundingClientRect();
      const containerBoundingRect = $container.getBoundingClientRect();

      const countryCenter = this.getElCenterPosition(countryBoundingRect);
      const containerCenter = this.getElCenterPosition(containerBoundingRect);
      const translateX = containerCenter.centerX - countryCenter.centerX;
      const translateY = containerCenter.centerY - countryCenter.centerY;
      this.$refs.countriesSVGContainer.style.transform = `scale(3.5) translate(${
        translateX + 100
      }px, ${translateY + 50}px)`;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  #worldRightsholderIo {
    will-change: transform;
  }
}
</style>
<style lang="scss" module>
.countriesSVG {
  transition: 1s all ease-in-out;
  will-change: transform;
}

.countriesSVGContainer {
}
</style>