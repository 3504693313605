import { loadView } from './helpers';

export default [
  {
    path: '/',
    name: 'Bulk',
    component: loadView('apps/bulk/BulkUpload'),
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: {
      render(h) {
        return h('router-view');
      },
    },
    children: [
      {
        path: '',
        name: 'Products',
        component: loadView('dashboard/Dashboard'),
      },
      {
        path: 'product/:productId',
        name: 'PublisherData',
        component: loadView('dashboard/product/PublisherData'),
        props: true,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login'),
  },
  {
    path: '/summary',
    name: 'BulkSummary',
    component: loadView('apps/components/publisherData/Summary'),
  },
  {
    path: '/publisher-data',
    name: 'PublisherData',
    component: loadView('apps/components/publisherData/PublisherData'),
  },
  {
    path: '/order/confirmation',
    name: 'OrderConfirmation',
    component: loadView('apps/views/order/OrderConfirmation'),
  },
  {
    path: '/auth/magic-link',
    name: 'MagicLink',
    component: loadView('auth/MagicLink'),
  },
];
