import { loadView } from './helpers';

export default [
  {
    path: '/',
    name: 'Search',
    component: loadView('Search'),
  },
  {
    path: '/title/:title',
    name: 'Title',
    component: loadView('Title'),
    props: true,
  },
  {
    path: '/artist/:artist_id?',
    name: 'Artist',
    component: loadView('Artist'),
    props: true,
  },
  {
    path: '/details/:id',
    name: 'Details',
    component: loadView('details/Details'),
    props: true,
    children: [
      {
        path: 'publishing_ids',
        name: 'DetailsPublishingIds',
        component: loadView('details/views/DetailsPublishingIds'),
      },
      {
        path: 'notes',
        name: 'PrivateNotes',
        component: loadView('details/views/PrivateNotes'),
      },
    ],
  },
  {
    path: '/release/:collection_id',
    name: 'Release',
    component: loadView('Release'),
    props: true,
  },
  {
    path: '/writer/:writer_id',
    name: 'Writer',
    component: loadView('Writer'),
    props: true,
  },
  {
    path: '/publisher/:publisher_id',
    name: 'Publisher',
    component: loadView('Publisher'),
    props: true,
  },
  {
    path: '/production/:production_id',
    name: 'Production',
    component: loadView('Production'),
    props: true,
  },
  {
    path: '/producer/:producer',
    name: 'Producer',
    component: loadView('Producer'),
    props: true,
  },
];
