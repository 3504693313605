import { loadView } from './helpers';

export default [
  {
    path: '/',
    name: 'Covered',
    component: loadView('apps/covered/Covered'),
  },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login'),
  },
  {
    path: '/auth/magic-link',
    name: 'MagicLink',
    component: loadView('auth/MagicLink'),
  },
];
