<template>
  <div :class="[$style.alert, variant && $style[variant]]">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
    },
  },
};
</script>

<style lang="scss" module>
.alert {
  padding: 1rem 0.75rem;
  line-height: 1.6;
  border-radius: 0.25rem;
  margin-bottom: 2rem;
}

.success {
  color: #065f46;
  background-color: #d1fae5;
}
</style>