import { loadView } from './helpers';

export default [
  {
    path: '/',
    redirect: '/catalogs',
  },
  {
    path: '/catalogs',
    component: {
      render: (h) => h('router-view'),
    },
    children: [
      {
        path: '',
        name: 'SelectCatalog',
        component: loadView('apps/catalog/SelectCatalog'),
      },
      {
        path: ':catalogId/search',
        name: 'SearchCatalogs',
        component: loadView('apps/catalog/SearchCatalogs'),
        redirect: ':catalogId/search/map',
        props: true,
        children: [
          {
            path: 'map',
            name: 'MapView',
            component: loadView('apps/catalog/views/MapView'),
            props: true,
          },
          {
            path: 'table',
            name: 'TableView',
            component: loadView('apps/catalog/views/TableView'),
            props: true,
          },
        ],
      },
    ],
  },
];
