
<script>
export default {
  props: {
    log: {},
  },
  render() {
    console.log(`Log:`, this.log);
    return null;
  },
};
</script>