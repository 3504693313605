import Vue from 'vue';
import '@/assets/styles/style.scss';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import { loadPlugins, registerBaseComponents } from '@/helpers';
const APP_VERSION = '0.0.1';

console.log(`APP_VERSION: ${APP_VERSION}`);

loadPlugins([
  'vue-final-modal',
  'vue-instantsearch',
  'vue-infinite-loading',
  'vue-fontawesome',
  'portal-vue',
  'vue-meta',
  'vue-gtag',
]);
registerBaseComponents(Vue);

Vue.config.productionTip = false;

try {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    userAgent
  );

  isTablet && window.screen.orientation.lock('landscape');
} catch (error) {
  console.error(error.toString());
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
