<template>
  <div
    class="base-stack"
    :class="{
      horizontal: horizontal,
      vertical: vertical,
    }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    vertical: {
      type: Boolean,
      defalt: false,
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-stack {
  display: flex;
  &.horizontal {
    flex-direction: row;
  }

  &.vertical {
    flex-direction: column;
  }
}
</style>
