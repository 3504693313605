/* https://github.com/FortAwesome/vue-fontawesome */
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSearch,
  faTimes,
  faCheck,
  faExpandAlt,
  faCompressAlt,
  faExchangeAlt,
  faSpinner,
  faTrash,
  faEye,
  // faTableList,
  faTable,
  faMap,
  faSync,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(
  faSearch,
  faTimes,
  faCheck,
  faExpandAlt,
  faCompressAlt,
  faExchangeAlt,
  faSpinner,
  faTrash,
  faEye,
  // faTableList,
  faTable,
  faMap,
  faSync,
  faPencilAlt
);

Vue.component('fa-icon', FontAwesomeIcon);
