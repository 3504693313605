import { loadView } from './helpers';

export default [
  {
    path: '/',
    name: 'Dashboard',
    component: loadView('apps/upload/Dashboard'),
  },
  // {
  // path: '/dashboard',
  // name: 'Dashboard',
  // component: {
  //   render(h) {
  //     return h('router-view');
  //   },
  // },
  // children: [
  // {
  //   path: '',
  //   name: 'UploadFiles',
  //   component: loadView('apps/upload/Dashboard'),
  // },
  {
    path: '/search/:fileUploadedId',
    name: 'Search',
    component: loadView('apps/upload/Search'),
    props: true,
  },
  // ],
  // },
  {
    path: '/login',
    name: 'Login',
    component: loadView('Login'),
  },
  //   {
  //     path: '/summary',
  //     name: 'BulkSummary',
  //     component: loadView('apps/components/publisherData/Summary'),
  //   },
  {
    path: '/auth/magic-link',
    name: 'MagicLink',
    component: loadView('auth/MagicLink'),
  },
];
