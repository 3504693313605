import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

import rightsholdersRoutes from './rightsholdersRoutes';
import bulkRoutes from './bulkRoutes';
import playlistsRoutes from './playlistRoutes';
import worldRoutes from './worldRoutes';
import uploadRoutes from './uploadRoutes';
import catalogRoutes from './catalogRoutes';
import coveredRoutes from './coveredRoutes';

import {
  TEST_BULK,
  TEST_PLAYLISTS,
  TEST_WORLD,
  TEST_UPLOAD,
  TEST_CATALOG,
  TEST_COVERED,
} from '@/constants/apps';
const host = window.location.host;
const parts = host.split('.');
let firstPart = parts[0];

if (process.env.NODE_ENV !== 'production') {
  if (TEST_BULK) {
    firstPart = 'bulk';
  } else if (TEST_PLAYLISTS) {
    firstPart = 'playlist';
  } else if (TEST_WORLD) {
    firstPart = 'world';
  } else if (TEST_UPLOAD) {
    firstPart = 'upload';
  } else if (TEST_CATALOG) {
    firstPart = 'catalog';
  } else if (TEST_COVERED) {
    firstPart = 'covered';
  }
}

const routesConfig = {
  rightsholders: rightsholdersRoutes,
  bulk: bulkRoutes,
  playlist: playlistsRoutes,
  world: worldRoutes,
  upload: uploadRoutes,
  catalog: catalogRoutes,
  covered: coveredRoutes,
};

let routes = Reflect.has(routesConfig, firstPart)
  ? routesConfig[firstPart]
  : routesConfig.rightsholders;
console.log('router', routes);
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
