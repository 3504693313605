<template>
  <div>
    <component :is="isMainApp ? 'Rightsholders' : 'router-view'" />
  </div>
</template>

<script>
import {
  TEST_BULK,
  TEST_PLAYLISTS,
  TEST_WORLD,
  TEST_UPLOAD,
  TEST_CATALOG,
  TEST_COVERED
} from "@/constants/apps";
const Rightsholders = () => import("@/views/Rightsholders");
import CountriesTest from "./CountriesTest.vue";
export default {
  components: {
    Rightsholders,
    CountriesTest,
  },
  computed: {
    isMainApp() {
      const host = window.location.host;
      const firstPart = host.split(".")[0];
      if (
        process.env.NODE_ENV !== "production" &&
        (TEST_BULK ||
          TEST_PLAYLISTS ||
          TEST_WORLD ||
          TEST_UPLOAD ||
          TEST_CATALOG || TEST_COVERED)
      )
        return false;

      if (
        ["bulk", "playlist", "world", "upload", "catalog", "covered"].includes(
          firstPart
        )
      ) {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>