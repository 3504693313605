import { loadView } from './helpers';

export default [
  {
    path: '/',
    redirect: '/iswc',
  },
  {
    path: '/iswc/:code?',
    name: 'WorldSearch',
    component: loadView('apps/world/worldSearch/WorldSearch'),
  },
];
