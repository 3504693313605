<template>
  <input
    class="base-input"
    v-bind="$attrs"
    @input="(e) => $emit('input', e.target.value)"
  />
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.base-input {
  padding: 1rem 0;
}
</style>
