<template>
  <component
    :is="tag"
    class="base-button"
    :class="[variant, buttonClass]"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
const VARIANTS = ["primary", "primary-outline", "primary-solid", "icon"];
export default {
  props: {
    tag: {
      type: String,
      default: "button",
    },
    variant: {
      type: String,
      validation: (value) => VARIANTS.includes(value),
      default: "primary",
    },
    buttonClass: {
      type: [String, Array, Object],
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #bd7eee;

.base-button {
  min-width: 4rem;
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;
  font-size: 1.2rem;
  padding: 1rem;

  &.primary {
    color: $primary;
    &-outline {
      color: $primary;
      border: 1px solid $primary;
      border-radius: 0.5rem;
    }
  }

  &.primary-solid {
    color: lighten($primary, 90%);
    background-color: $primary;
  }

  &:disabled {
    color: rgba(149, 149, 149, 0.455);
    cursor: not-allowed;
  }

  &.icon {
    background: transparent;
    border: none;
    padding: 0;
    min-width: auto;
  }
}
</style>
