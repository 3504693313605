import Vue from 'vue'
import VueGtag from 'vue-gtag'
import router from '@/router'

Vue.use(VueGtag, {
    config: {
        id: 'UA-129660810-1'
    },
    appName: 'Rightsholders',
    pageTrackerScreenviewEnabled: true
}, router)