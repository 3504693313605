<template>
  <div class="group" :class="$style.basePageTitle">
    <component :is="component" v-bind="$attrs" v-on="$listeners">
      <label
        ><strong>{{ label }}</strong></label
      >
      <BaseHeadline :class="$style.pageTitle" :text="text" />
    </component>
  </div>
</template>

<script>
export default {
  props: {
    component: {
      type: String,
      default: "h1",
    },
    label: {
      type: String,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" module>
.pageTitle {
  font-size: 36px;
  @include tablet {
    font-size: 38px;
    line-height: 46px;
  }
  @include tablet-landscape-only {
    font-size: 48px;
  }

  @include desktop {
    font-size: 50px;
  }
}

.basePageTitle {
}
</style>