<template>
  <fa-icon v-if="isVisible" icon="spinner" spin v-bind="$attrs" />
</template>
<script>
export default {
  name: "BaseSpinner",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (!value) {
          clearTimeout(this.spinnerShowTimeout);
          this.isVisible = false;
          return;
        }

        this.initDelayedShowSpinner();
      },
    },
  },
  methods: {
    initDelayedShowSpinner() {
      clearTimeout(this.spinnerShowTimeout);
      this.spinnerShowTimeout = setTimeout(() => {
        this.isVisible = true;
      }, this.delay);
    },
  },
};
</script>